<script setup>

const loading = ref(false)
const search = ref('')
const { Settings } = useSettings()

// definePageMeta({
//   middleware: (from, to) => {
//     const { Settings } = useSettings()
//     if (Settings.value.showBlobInHome && Settings.value.blobIdInHome) {
//       // return navigateTo(`/b/${Settings.value.blobIdInHome}`)
//     }
//     return
//   }
// })
// const { data: posts } = await useLazyFetch(`/api/post/${route.params.id}`, { key: 'pp' + route.params.id })

const fetchApi = `/api/post` + ((Settings.value.showBlobInHome && Settings.value.blobIdInHome) ? `/${Settings.value.blobIdInHome}` : '')
const { data: blob } = (Settings.value.showBlobInHome && Settings.value.blobIdInHome) ? await useFetch(`/api/blob/${Settings.value.blobIdInHome}`, { key: 'aa' + Settings.value.blobIdInHome }) : ({ data: null  })
const { data: posts = [], pending, refresh, error } = await useFetch(fetchApi)

const preloadImage = computed( () => posts?.value?.length && posts?.value[0]?.image ?
  '/media/' + posts?.value[0]?.image.replace(/.jpg$/, '.webp') : 
  '/media/fallbackImage.webp')

useHead( {
  title: Settings?.value?.name || 'Blob',
  link: [
    { rel: 'alternate', type: 'application/rss+xml', title: Settings?.value?.name || 'Blob', href: '/feed' },
    { rel: 'preload', as: 'image', href: preloadImage },
    { rel: 'preload', as: 'image', href: '/media/logo' }
  ]
})


const infiniteScrolling = async (isIntersecting, entries, observer) => {
  if (isIntersecting && posts?.value?.length) {
    loading.value = true

    if (Settings.value.showBlobInHome && Settings.value.blobIdInHome) {
      const sortBy = blob.value.sortBy
      const timestamp = new Date(posts.value[posts.value.length-1].date).getTime()
      const params = new URLSearchParams({ after: sortBy === 'date' ? timestamp : posts.value[posts.value.length-1][sortBy] })
      const { data: ret } = await useLazyFetch(`${fetchApi}?${params}`)
      if (ret?.value?.length) {
        posts?.value.push(...ret.value)
      }  
      
    } else {
      const { data: ret } = await useLazyFetch(`${fetchApi}?after=${posts?.value[posts?.value?.length-1].date}`)
      if (ret?.value?.length) {
        posts?.value.push(...ret.value)
      }
    }
    loading.value = false
  }
}

</script>

<template>
  <div>
    <Blobs id="blob_blobs"/>
    <p v-if='Settings.tagline' v-html='Settings.tagline' id="blob_tagline"/>
    <Post v-for='(post, id) in posts'
      :key='post.id'
      :post='post'
      @remove="posts.splice(id, 1)"
      @toggleVisibility="post.visible = !post.visible"/>
    <div class='ma-12 text-center' v-intersect="infiniteScrolling">
      <v-progress-circular v-if='loading' indeterminate />
    </div>
  </div>
</template>
